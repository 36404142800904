import { useEffect } from 'react';
import style from './App.module.scss';
import { Grid } from './components/Content';

import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Work } from './components/Pages/Work';
import { About } from './components/Pages/About';
import { Resume } from './components/Pages/Resume';

  const App = () => {
    useEffect(() => {
      if (window.location.pathname !== '/work') {
        return window.location.pathname = '/work';
      }
    }, []);
  
    return (
      <div className={style.context}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Grid />}>
              <Route index path='work' element={<Work />} />
              {/* <Route path='work' element={<Work />} /> */}
              <Route path="about" element={<About />} />
              <Route path="resume" element={<Resume />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
  
  export default App;