import { Header } from '../../Header/';
import style from './About.module.scss';
import darkPicture from './assets/black_and_white.jpg';

export const About = ({
  ...props
}) => {
  return (
    <>
      <Header
        line={`👋 Greetings,`}
        line1={`I’m a design-obsessed problem solver,`}
        line2={`who lives to transform chaos into clean, intuitive experiences with over 15 years in UX/UI.`}
      />
      <div className={[style.content, style.pd].join(" ")}>
        <div className={[style.container].join(" ")}>
          <div className={style.row}>
            <div>
              <p>
                I’ve mastered the craft of turning complex ideas into seamless,
                user-friendly designs. Think of me as an interface
                whisperer—whether that’s fixing what’s broken or creating
                something fresh that makes users say, “Wow!”
                <span className={style.annotation}>
                  Making the digital world a little easier to navigate.
                </span>
              </p>
            </div>
            <div>
              <p>
                When I’m not wrestling with pixels and prototypes, I’m busy
                being a dad to three 👶 👶 👶 amazing kids who keep my
                problem-solving skills sharp (because, let’s be honest,
                negotiating with toddlers is UX in its purest form).
                <span className={style.annotation}>
                  {" "}
                  So if you're ready to talk design, tech, or even trade dad
                  jokes, I’m your guy!
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className={style.cols}>
          <div className={[style.left].join(" ")}>
            <img src={darkPicture} />
          </div>

          <div className={[style.right].join(" ")}>
            <div>
              <p>
                <div>
                  <span className={style.years}>11 years</span>
                  <span className={style.expirience}>OF EXPERIENCE</span>
                </div>
                <span className={style.solutions}>
                  IN <span>FLEET</span> and <span>vehicle</span> SOLUTION,{" "}
                  <span>B2B</span>/<span>SAAS</span>
                </span>
              </p>
            </div>

            <div>
              <p>
                <span className={style.believe}>
                  I believe great design can solve just about any problem
                </span>
                <span className={style.except}>
                  (except maybe my coffee addiction—but I’m working on that!).
                </span>
                <span className={style.letsConnect}>
                  <span>Let’s connect</span> and make something awesome!
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className={[style.container].join(" ")}>
          <div className={[style.row, style.paragraph].join(" ")}>
            <div>
              <p>
                <span className={style.number}>01/</span>
                <span className={style.serviceName}>Website Design</span>
              </p>
            </div>
            <div>
              <p>
                Designing visually appealing, intuitive websites with seamless
                functionality, optimized performance, and clear navigation for
                better engagement.
              </p>
            </div>
          </div>
        </div>

        <div className={[style.container].join(" ")}>
          <div className={[style.row, style.paragraph].join(" ")}>
            <div>
              <p>
                <span className={style.number}>02/</span>
                <span className={style.serviceName}>Product Design</span>
              </p>
            </div>
            <div>
              <p>
                Designing functional, user-first products that drive adoption,
                satisfaction, and real business results.
              </p>
            </div>
          </div>
        </div>

        <div className={[style.container].join(" ")}>
          <div className={[style.row, style.paragraph].join(" ")}>
            <div>
              <p>
                <span className={style.number}>03/</span>
                <span className={style.serviceName}>Branding & Strategy</span>
              </p>
            </div>
            <div>
              <p>
                Crafting impactful brands across diverse industries, guiding
                each from strategic vision to complete identity, and helping
                them thrive and stand out.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
