// Contact Icons
import { ReactComponent as Email} from './assets/icons/contact/email.svg';
import { ReactComponent as Link} from './assets/icons/contact/link.svg';
import { ReactComponent as Phone} from './assets/icons/contact/phone.svg';
import { ReactComponent as Address} from './assets/icons/contact/location.svg';
import { ReactComponent as Profile} from './assets/icons/contact/profile.svg';
// Design Skills Icons
import { ReactComponent as Figma} from './assets/icons/design_skills/figma.svg';
import { ReactComponent as Sketch} from './assets/icons/design_skills/sketch.svg';
import { ReactComponent as AdobeXD} from './assets/icons/design_skills/adobexd.svg';
import { ReactComponent as Axure} from './assets/icons/design_skills/axure.svg';
// Achievements Icons
import { ReactComponent as Solera} from './assets/icons/companies/solera.svg';
import { ReactComponent as DealerSocket} from './assets/icons/companies/dealerSocket.svg';
import { ReactComponent as DealerFire} from './assets/icons/companies/df_logo.svg';
import { ReactComponent as Uzinfocom} from './assets/icons/companies/uzinfocom.svg';

// Front-End Skills Icons
import { ReactComponent as Git} from './assets/icons/front_end_skills/git.svg';
import { ReactComponent as JavaScript} from './assets/icons/front_end_skills/javaScript.svg';
import { ReactComponent as ReactJS} from './assets/icons/front_end_skills/react_js.svg';
import { ReactComponent as Storybook} from './assets/icons/front_end_skills/storybook.svg';
import { ReactComponent as Redux} from './assets/icons/front_end_skills/redux.svg';
import { ReactComponent as Saas} from './assets/icons/front_end_skills/saas.svg';
import { ReactComponent as Sql} from './assets/icons/front_end_skills/sql_db.svg';
import { ReactComponent as Html5} from './assets/icons/front_end_skills/HTML5.svg';
import { ReactComponent as Bootstrap} from './assets/icons/front_end_skills/bootstrap.svg';
import { ReactComponent as MaterialUI} from './assets/icons/front_end_skills/MaterialUI.svg';

export const workList = [
  {
    logo: <Solera />,
    companyName: `Solera Holding Inc`,
    title: `UI/UX Designer III`,
    dateFrom: `Oct 2021`,
    dateTo: `Present`,
    dateTotal: `3 years`,
    link: `https://www.solera.com/`,
    jobDuties: [
      `Create wireframes and interactive prototypes, delivering high-fidelity designs.`,
      `Collaborate with product owners to provide UI/UX solutions aligned with business goals.`,
      `Work in Agile/Scrum with UX teams and developers.`,
      `Design and maintain UI/UX flows for B2B/CRM systems.`,
      `Conduct UX research, user testing, and A/B tests.`,
      `Maintain and manage Design Systems components.`,
    ]
  },
  {
    logo: <DealerSocket />,
    companyName: `Solera Holding Inc`,
    title: `UI/UX Designer II`,
    dateFrom: `Sep 2019`,
    dateTo: `Oct 2021`,
    dateTotal: `3 years`,
    link: `https://dealersocket.com/`,
    jobDuties: [
      `Develop high-quality wireframes and prototypes from UX analysis.`,
      `Collaborate with product owners, developers, and stakeholders to deliver top-notch designs.`,
      `Engage in Agile/Scrum processes with UX teams and developers.`,
      `Conduct UX research, user testing, and A/B tests.`,
      `Manage and maintain Design Systems components.`,
    ]
  },
  {
    logo: <DealerFire />,
    companyName: `DealerFire Kaliningrad LLC`,
    title: `UI/UX Designer I`,
    dateFrom: `Sep 2015`,
    dateTo: `Sep 2019`,
    dateTotal: `4 years`,
    link: `https://www.dealerfire.com/`,
    jobDuties: [
      `Design wireframes, prototypes.`,
      `Scrum with the design team.`,
      `Design and maintain UX/UI for B2B/CRM systems.`,
      `Conduct UX research, user testing, and A/B tests.`,
      `Create animations and interactive prototype.`,
    ]
  },
  {
    logo: <DealerFire />,
    companyName: `DealerFire Tashkent`,
    title: `UI/UX Designer`,
    dateFrom: `Jan 2013`,
    dateTo: `Sep 2015`,
    dateTotal: `2 years`,
    link: `https://www.dealerfire.com/`,
    jobDuties: [
      `Collaborate with design team, developers, product owners and stakeholders.`,
      `Design high-quality UIs for mobile and B2B systems.`,
      `Skilled in Photoshop, Illustrator, and web design tools.`,
      `Use UX research to drive business goals.`,
      `Participate in Agile/Scrum with developers.`,
    ]
  },
  {
    logo: <Uzinfocom />,
    companyName: `Uzinfocom`,
    title: `Web designer and Front-End developer`,
    dateFrom: `Jul 2006`,
    dateTo: `2010`,
    dateTotal: `4 years`,
    link: `https://uzinfocom.uz/en`,
    jobDuties: [
      `Designed websites and web portals.`,
      `Converted mockups into web pages using HTML/CSS languages.`,
      `Acquired expertise in working with PHP smart templates.`,
      `Enhanced web design capabilities.`,
      `Gained initial experience collaborating with a developer team.`,
    ]
  },
]

export const contactInfo = [
  // {
  //   icon: <Email />,
  //   name: `Email`,
  //   contactName: `vemelin@gmail.com`,
  //   type: true,
  // },
  // {
  //   icon: <Link />,
  //   name: `Website`,
  //   contactName: `www.execute.design`,
  //   type: true,
  // },
  {
    icon: <Link />,
    name: `GitHub`,
    contactName: `www.github.com/vemelin`,
    type: true,
  },
  {
    icon: <Link />,
    name: `LinkedIn`,
    contactName: `www.linkedin.com/in/vemelin/`,
    type: true,
  },
  // {
  //   icon: <Phone />,
  //   name: `Phone`,
  //   contactName: `(+1) 214 433 2612`,
  //   type: false,
  // },
  {
    icon: <Address />,
    name: `Address`,
    // contactName: `Coppell, Dallas County, Texas`,
    contactName: `Coppell, Texas`,
    type: false,
  },
  {
    icon: <Profile />,
    name: `US Visa`,
    contactName: `Green Card`,
    type: false,
  },
]

export const designSkills = [
  {
    icon: <Figma />,
    name: `Figma`,
    gap: `7px`,
  },
  {
    icon: <Sketch />,
    name: `Sketch`,
    gap: `7px`,
  },
  {
    icon: <AdobeXD />,
    name: `Adobe XD`,
    gap: `7px`,
  },
  {
    icon: <Axure />,
    name: ``,
    gap: ``,
  },
  {
    icon: '',
    name: `UI/UX Design`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Product Design`,
    gap: `0`,
  },
  {
    icon: '',
    name: `UX Research`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Usability`,
    gap: `0`,
  },
  {
    icon: '',
    name: `A/B Testing`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Critical Thinking`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Design System`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Interaction Design`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Prototyping`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Wireframing`,
    gap: `0`,
  },
  {
    icon: '',
    name: `UI QA`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Agile/Scrum`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Jira`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Mobile Design (IOS/Android)`,
    gap: `0`,
  },
]

export const frontEndSkills = [
  {
    icon: <Git />,
    name: `Git`,
    gap: `7px`,
  },
  {
    icon: <JavaScript />,
    name: `JavaScript`,
    gap: `7px`,
  },
  {
    icon: <ReactJS />,
    name: `React JS`,
    gap: `7px`,
  },
  {
    icon: <Storybook />,
    name: `Storybook`,
    gap: `7px`,
  },
  {
    icon: <Redux />,
    name: `Redux`,
    gap: `7px`,
  },
  {
    icon: <Saas />,
    name: `SCSS`,
    gap: `7px`,
  },
  {
    icon: <Sql />,
    name: `SQL`,
    gap: `7px`,
  },
  {
    icon: <Html5 />,
    name: `HTML5`,
    gap: `7px`,
  },
  {
    icon: <Bootstrap />,
    name: `Bootstrap`,
    gap: `7px`,
  },
  {
    icon: <MaterialUI />,
    name: `Material UI`,
    gap: `7px`,
  },
]