import { React } from 'react';
import { Link } from "react-router-dom";
import style from './NavBar.module.scss';
import { motion } from "framer-motion";
import { useState } from "react";
import { ReactComponent as ExecuteDesign} from './assets/execute.svg';
import { ReactComponent as EditIcon} from './assets/edit-icon.svg';


let tabs = [
  { id: "work", label: "Work" },
  { id: "resume", label: "Resume" },
  { id: "about", label: "About" },
];

export const NavBar = ({ ...props }) => {
  let [activeTab, setActiveTab] = useState(tabs[0].id);

  if(activeTab === 'resume') {
    document.body.className = 'resume';
  }
  if (activeTab === 'work') {
    document.body.className = 'work';
  }
  if (activeTab === 'about') {
    document.body.className = 'about';
  }

  return (
    <div className={style.navigation}>
      <div className={style.container}>

        <div className={style.userName}>
          <div className={style.avatar}>
            <img decoding='async' loading='lazy' src='https://framerusercontent.com/images/NWlDr9Bh9gYxYTuknhoXgSasSA.jpg'/>
          </div>
          <ExecuteDesign />
        </div>

        <div className={style.links}>
          <ul>
            {tabs.map((tab) => (
              <li
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`${
                  activeTab === tab.id ? "" : "hover:text-white/60"
                } relative rounded-full px-3 py-1.5 text-sm font-medium text-white outline-sky-400 transition focus-visible:outline-2`}
                style={{
                  WebkitTapHighlightColor: "transparent",
                }}
              >
                {activeTab === tab.id && (
                  <motion.span 
                    layoutId="bubble"
                    className={style.active}
                    style={{ borderRadius: 9999 }}
                    transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                  />
                )}
                {/* <a href='#' className={[activeTab === tab.id  ? style.activeLink : ' '].join(' ')}>{tab.label}</a> */}
                {/* <Link to={tab.id === 'work' ? '/' : tab.id}>{tab.label}</Link> */}
                <Link to={tab.id}>{tab.label}</Link>
              </li>
            ))}
          </ul>
          {/* <ul>
            <li>
              <a href='#' className={style.active}>Work</a>
            </li>
            <li>
              <a href='#'>Resume</a>
            </li>
            <li>
              <a href='#'>About</a>
            </li>
          </ul> */}
        </div>

        <div className={style.contactMe}>
            <span>Contact</span>
            <EditIcon />
        </div>

      </div>
    </div>
  );
};
