import React from "react"
import style from './Header.module.scss'

export const Header = ({
  ...props
}) => {
  return (
    <div className={style.header}>
      <div>
        <div>
          <p>
            {props.line ? props.line : '👋 Hi there,'}<br />
            {props.line1}
          </p>
        </div>
      <div>
        <h1>{props.line2}</h1>
      </div>
    </div>
    </div>
  )
}
