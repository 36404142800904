// import style from './Grid.module.scss';
// import { Header } from '../Header/index';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../NavBar/index';

export const Grid = ({
  ...props
}) => {
  return(
    <div>
      {/* <Header text={'test'} /> */}
      <Outlet />
      <NavBar />
    </div>
  )
} 