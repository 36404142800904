import { Header } from '../../Header/';
import style from './Resume.module.scss';
import { contactInfo, designSkills, frontEndSkills, workList } from './Contact';

export const Resume = ({
  ...props
}) => {
  return (
    <>
      <Header
        line={`✌シ Grab some popcorn!`}
        line1={`From big wins to late-night breakthroughs—welcome to the highlight reel of my journey and`}
        line2={`Achievements`}
      />
      <div className={style.content}>

        {/* LEFT */}
        <div className={style.left}>

          {/* <h3 className={style.exp}>Experience</h3> */}
          <div className={style.grid}>
            {workList.map((item => <div>
              <div className={style.row}>
                <div className={style.heading}>
                  <div className={style.company}>
                    <div className={style.logo}>
                      <a href={item.link} className={style.companyName} target='_blank' rel='noreferrer'>
                        {item.logo}
                      </a>
                    </div>
                    <div>
                      <a href={item.link} className={style.companyName} target='_blank' rel='noreferrer'>
                        {item.companyName}
                      </a>
                      <p className={style.roleTitle}>{item.title}</p>
                    </div>
                  </div>
                  <div className={style.date}>
                    <p><span>{item.dateFrom}</span> – <span>{item.dateTo}</span></p>
                    <p>{item.dateTotal}</p>
                  </div>
                </div>
                <div className={style.description}>
                  <ul>
                    {item.jobDuties.map((el) => (
                      <li>{el}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>))}
          </div>

          <h3 className={style.edu}>Education</h3>
          <div className={style.grid}>
            <div className={style.education}>
              <div className={style.leftCol}>
                <a href='https://tersu.uz/' target='_blank' rel='noreferrer'>Termez State University</a>
                <p><span>Specialty</span>: Economics <span className={style.rate}>Rate</span>: 85.4%</p>
              </div>
              <div className={style.rightCol}>
                <div>
                  <p><span>September 2001 — July 2005</span></p>
                  <p>4 years</p>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* RIGHT */}
        <div className={style.right}>
          {/* <h3>Contact</h3> */}
          <div className={style.grid}>
            {contactInfo.map((item, index) => (
              <div className={style.item} key={index}>
                <div className={style.icon}>{item.icon}</div>
                <div className={style.info}>
                  <p>{item.name}</p>
                  {item.type ? (
                    <a href={`http://${item.contactName}`} target='_blank' rel='noreferrer'>
                      {item.contactName}
                    </a>
                  ) : (
                    <span>{item.contactName}</span>
                  )}
                </div>
              </div>
            ))}
          </div>

          <h3>Design Skills</h3>
          <div className={style.badges}>
            {designSkills.map((item => (
              <div>
                <div
                  className={style.badge}
                  style={{ gap: `${item.icon ? item.gap : ''}` }}
                >
                  <div>{item.icon}</div>
                  <span>{item.name}</span>
                </div>
              </div>
            )))}
          </div>

          <h3>Front-End Skills</h3>
          <div className={style.badges}>
            {frontEndSkills.map((i) => (
              <>
                <div
                  className={style.badge}
                  style={{ gap: `${i.icon ? i.gap : ''}` }}
                >
                  <div>{i.icon}</div>
                  <span>{i.name}</span>
                </div>
              </>
            ))}
          </div>

          <h3>Languages</h3>
          <div className={style.languages}>
            <ul>
              <li>
                <span>English</span> - Advanced
              </li>
              <li>
                <span>Russian</span> - Native
              </li>
              <li>
                <span>Uzbek</span> - Advanced
              </li>
            </ul>
          </div>

          <h3>Interests</h3>
          <div className={style.interests}>
            <ul>
              <li>Mountain biking</li>
              <li>Snowboarding</li>
              <li>Industrial Design</li>
            </ul>
          </div>
        </div>
        
      </div>
    </>
  );
}
